/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
import YWheader from "../YWheader";
import { sessionStorage } from "../../../../assets/myjs/glovar.js";
import axios from "axios";
export default {
  name: "ywProject",
  components: {
    YWheader
  },
  data() {
    return {
      list: [],
      listdata: [],
      title: "跟踪批注列表信息",
      // rateVal: 3,
      loading: false,
      finished: false,
      page: -1,
      limit: 20,
      total: "",
      solId: ""
    };
  },
  created() {
    setTimeout(() => {
      this.moreLoad();
    }, 2000);
  },
  methods: {
    // test(){
    //   this.solId=this.$route.params.solId;
    // },

    moreLoad() {
      this.page += 1;
      this.onLoad();
    },
    onLoad() {
      var _this = this;
      let url = _baseUrl + `/mobile/ywgz/findAllGzPzxx.do?pageIndex=${this.page}&pageSize=${this.limit}`;
      axios.post(url).then(res => {
        _this.list = _this.list.concat(res.data.rowList);
        _this.loading = false;
        _this.total = res.data.total;
        if (_this.list.length >= _this.total) {
          _this.finished = true;
        } else {
          _this.finished = false;
        }
      });
    },
    onInfo(item) {
      const objItem = JSON.stringify(item);
      sessionStorage.setItem("ywNoteData", objItem);
      this.$router.push("/ywNoteInfo");
    }
  }
};